import { defineStore } from 'pinia'

export const useModalStore = defineStore('modalStore', {
    state: () => ({
        showLoginModal: false,
        showLogoutModal: false,
        showSignUpModal: false,
        showVerifyOTPModal: false,
        showForgotPasswordModal: false,
        showAddBankModal: false,
        showBankAddedModal: false,
        showVerfiyPhoneModal: false,
        showPhoneVerifiedModal: false,

        showVerifyEmailModal: false,
        showVerifyEmailOtpModal: false,
        showEmailVerifiedModal: false,

        showVerifyLineModal: false,

        showVerifyTelegramModal: false,

        showSelectBankModal: false,

        showSearchModal: false,
        showLeftBar: false,

        showGameDenyModal: false,

        showCancelDepositModal: false,
        showCancelPromoModal: false,
        showFundsFunctionModal: false,
        showSupportFunctionModal: false,
        showKycRequired: false,
        showKycVerificationPending: false,

        showPromoBtiModal: false,
        showOneTimeVerificationBank: false,

        showCashbackRebateModal: false,

        showNoBankModal: false,
        showVerifyBankModal: false,

        showCancelCodepayModal: false,
    }),
})
